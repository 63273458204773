.fuc-buttons .btn {
  margin-bottom: 0.5rem;
}

.fuc-add {
  position: relative;
  overflow: hidden;
}
.fuc-add input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
}

.fuc-container {
  border: 2px dashed #dee2e6;
  border-radius: 0.5rem;
  background: #f8f9fa;
  padding: 2rem 1rem;
}

.fuc-files.row {
  justify-content: center;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.fuc-files.row > .col-auto {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
}

.fuc-file .card {
  width: 12rem;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 0.875rem;
}
.fuc-file .card .card-header {
  background: #fff;
  border-bottom: 0;
}
.fuc-file .card .card-header .col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fuc-file .card .card-header .btn-close {
  margin-left: 0.25rem;
}
.fuc-file .card .card-body {
  height: 9rem;
  padding-top: 0;
}
.fuc-file .card .card-body .progress {
  margin: 3rem 0;
}
.fuc-file[data-file-upload-status=done] .card-body {
  padding: 0;
}
.fuc-file[data-file-upload-status=failed] .card {
  border-color: #dc3545 !important;
}

.fuc-file-thumbnail {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.fuc-file-thumbnail > span {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
  padding: 1rem;
}
.fuc-file-thumbnail > img {
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fuc-dropzone {
  text-align: center;
  font-weight: bold;
}

.fuc-file-thumbnail > span {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ced4da' viewBox='0 0 16 16'%3e%3cpath d='M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z'/%3e%3c/svg%3e");
}

[data-content-type="text/plain"] .fuc-file-thumbnail > span,
[data-content-type="application/rtf"] .fuc-file-thumbnail > span,
[data-content-type="application/pdf"] .fuc-file-thumbnail > span,
[data-content-type="application/msword"] .fuc-file-thumbnail > span,
[data-content-type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"] .fuc-file-thumbnail > span,
[data-content-type="application/vnd.oasis.opendocument.text"] .fuc-file-thumbnail > span {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ced4da' viewBox='0 0 16 16'%3e%3cpath d='M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5'/%3e%3cpath d='M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z'/%3e%3c/svg%3e");
}

[data-content-type="application/vnd.ms-excel"] .fuc-file-thumbnail > span,
[data-content-type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"] .fuc-file-thumbnail > span,
[data-content-type="application/vnd.oasis.opendocument.spreadsheet"] .fuc-file-thumbnail > span {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ced4da' viewBox='0 0 16 16'%3e%3cpath d='M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5zM3 12v-2h2v2zm0 1h2v2H4a1 1 0 0 1-1-1zm3 2v-2h3v2zm4 0v-2h3v1a1 1 0 0 1-1 1zm3-3h-3v-2h3zm-7 0v-2h3v2z'/%3e%3c/svg%3e");
}

[data-content-type^="audio/"] .fuc-file-thumbnail > span {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ced4da' viewBox='0 0 16 16'%3e%3cpath d='M11 6.64a1 1 0 0 0-1.243-.97l-1 .25A1 1 0 0 0 8 6.89v4.306A2.6 2.6 0 0 0 7 11c-.5 0-.974.134-1.338.377-.36.24-.662.628-.662 1.123s.301.883.662 1.123c.364.243.839.377 1.338.377s.974-.134 1.338-.377c.36-.24.662-.628.662-1.123V8.89l2-.5z'/%3e%3cpath d='M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z'/%3e%3c/svg%3e");
}

[data-content-type^="video/"] .fuc-file-thumbnail > span {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ced4da' viewBox='0 0 16 16'%3e%3cpath d='M6 6.883v4.234a.5.5 0 0 0 .757.429l3.528-2.117a.5.5 0 0 0 0-.858L6.757 6.454a.5.5 0 0 0-.757.43z'/%3e%3cpath d='M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z'/%3e%3c/svg%3e");
}

[data-content-type$="/x-7z-compressed"] .fuc-file-thumbnail > span,
[data-content-type$="/vnd.rar"] .fuc-file-thumbnail > span,
[data-content-type$="/x-tar"] .fuc-file-thumbnail > span,
[data-content-type$="/zip"] .fuc-file-thumbnail > span,
[data-content-type$="/gzip"] .fuc-file-thumbnail > span,
[data-content-type$="/x-bzip2"] .fuc-file-thumbnail > span,
[data-content-type$="/x-bzip"] .fuc-file-thumbnail > span,
[data-content-type$="/x-7z-compressed"] .fuc-file-thumbnail > span {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ced4da' viewBox='0 0 16 16'%3e%3cpath d='M5 7.5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v.938l.4 1.599a1 1 0 0 1-.416 1.074l-.93.62a1 1 0 0 1-1.11 0l-.929-.62a1 1 0 0 1-.415-1.074L5 8.438zm2 0H6v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.929-.62-.4-1.598A1 1 0 0 1 7 8.438z'/%3e%3cpath d='M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1h-2v1h-1v1h1v1h-1v1h1v1H6V5H5V4h1V3H5V2h1V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z'/%3e%3c/svg%3e");
}

/*# sourceMappingURL=file-upload-control-bootstrap5.css.map */

.fuc-buttons .btn {
  margin-bottom: 0.5rem;
}

.fuc-add {
  position: relative;
  overflow: hidden;

  input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
  }
}

.fuc-container {
  border: 2px dashed $file-upload-control-border-color;
  border-radius: $file-upload-control-border-radius;
  background: $file-upload-control-background;
  padding: 2rem 1rem;
}

.fuc-files.row {
  justify-content: center;
  $gutter: 0.5rem;
  margin-left: -$gutter;
  margin-right: -$gutter;

  > .col-auto {
    padding-left: $gutter;
    padding-right: $gutter;
    margin-bottom: 2 * $gutter;
  }
}

.fuc-file {
  .card {
    width: $file-upload-control-thumbnail-width;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: $file-upload-control-file-font-size;

    .card-header {
      background: #fff;
      border-bottom: 0;

      .col {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .btn-close {
        margin-left: 0.25rem;
      }
    }

    .card-body {
      height: $file-upload-control-thumbnail-height;
      padding-top: 0;

      .progress {
        margin: 3rem 0;
      }
    }
  }

  &[data-file-upload-status=done] {
    .card-body {
      padding: 0;
    }
  }

  &[data-file-upload-status=failed] {
    .card {
      border-color: $file-upload-control-error-color !important;
    }
  }
}


.fuc-file-thumbnail {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;

  & > span {
    display: block;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;
    padding: 1rem;
  }

  & > img {
    display: block;
    position: absolute;
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.fuc-dropzone {
  text-align: center;
  font-weight: bold;
}
